@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /*
    * Special header links
    */
  .header-link {
    position: relative;
  }
  .header-link::after {
    position: absolute;
    content: "";
    top: calc(100% - 4px);
    left: 0;
    width: 100%;
    height: 4px;
    background: #3498db;
  }

  /*
    * CSS loader effect
    */
  .loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loader div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #555;
    animation: loader 1.2s linear infinite;
  }
  .loader div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  .loader div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  .loader div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  .loader div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  .loader div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  .loader div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  .loader div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  .loader div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  .loader div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
  @keyframes loader {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  /* Webkit-based browsers styling */

  input[type="range"] {
    -webkit-appearance: none;
  }

  progress {
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-progress-bar {
    background-color: #414b58;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: "100%";
    height: 2px;
    background: #c4c4c4;
    border: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border: none;
    border-radius: 14px;
    background: #414b58;
    margin-top: -6px;
    cursor: pointer;
  }

  /* Mozilla input-range styling */

  input[type="range"]::-moz-range-track {
    width: "100%";
    height: 2px;
    background: #c4c4c4;
    border: none;
  }

  input[type="range"]::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background: #414b58;
    cursor: pointer;
  }

  input[type="range"]::-moz-range-progress {
    background-color: #414b58;
    height: 2px;
  }
}

/*
* Fixed layout for #root component to always have Header on top and Footer
* on the bottom.
*/
#root {
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 100vh;
  justify-content: space-between;
  font-size: 16px;
}

.trashCan {
  display: none;
}

.row:hover .trashCan {
  display: block;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
